import React, { useContext } from "react";
import { AppStateContext } from "../state/AppStateContext";
import Layout from "./common/Layout";
import LoadingModal from "./modals/LoadingModal";
import { useTranslation } from "react-i18next";
import CompanyBasicInfo from "./CompanyBasicInfo";
import CredentialsInfo from "./CredentialsInfo";

const Home: React.FC = () => {
  const appContext = useContext(AppStateContext);
  const { t } = useTranslation(["translation"]);
  
  return (
    <Layout title={t("CompanyRegister.frontpage.heading")}>
      <CredentialsInfo/>
      <br/>
      <CompanyBasicInfo/>
      <LoadingModal isOpen={appContext.isLoadingApp} contentLabel={"Loading"} message={"Loading content..."}/>
    </Layout>
  );
};

export default Home;
