export interface BasicMessageWrapper {
  message: BasicMessage,
  isRead: boolean
}

export interface BasicMessage {
  connection_id: string,
  message_id: string,
  content: string,
  state: string,
  sent_time: string
}

export interface AvailableCredential {
  cred_def_id: string,
  title_fi: string,
  description_fi: string,
  title_en: string,
  description_en: string
}

export const CredentialExhangeReadyStates = ["credential_received", "credential_acked", "credential_revoked", "abandoned", "deleted"];

export enum Issuer {
  COMPANYREGISTER = "companyRegister",
  VERO = "vero",
}