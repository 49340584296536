import axios, { AxiosResponse } from 'axios'
import { Company } from '../models/OmaXModels';

const apiRootUrl = process.env.REACT_APP_OMAYRITYS_FUNCTIONS_URL /*|| "https://omayritys-functions.azurewebsites.net/api"*/ ||  'http://localhost:7071/api'

const api = axios.create({
  baseURL: apiRootUrl
})

export async function GetCompany(businessId: string): Promise<AxiosResponse<Company>> {
  return api.get("/Companies/GetCompanyByBid", {params: {bid: businessId}});
}



export async function GetCompaniesByAuthCookie(token: string|undefined): Promise<AxiosResponse<Company[]>> {
  return api.get("/Companies/GetCompaniesByAuthCookie", { withCredentials: true, params: {token} });
}


export async function Authenticate(): Promise<AxiosResponse<unknown>> {
  return api.get("/authenticate", { params: { relayStateAction: "Yritysportaali" } });
}

export async function Authenticate2(relayState: string, lang: string): Promise<AxiosResponse<{ redirectUri: string }>> {
  return api.get("/authenticateX", { params: { relayStateAction: relayState, language: lang } });
}

export async function Logout(): Promise<AxiosResponse<void>> {
  debugger;
  return api.get("/logout", { withCredentials: true });
}