/** @jsxImportSource @emotion/react */
import { jsx } from '@emotion/react'
import React from 'react';
import { suomifiDesignTokens as tokens } from "suomifi-ui-components";
import { ButtonLink } from "./InputStyles";
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';


const Header: React.FC = () => {
  
  const { t, i18n } = useTranslation()
  
  const { pathname } = useLocation();
  const loggedIn = pathname !== "/login";
  
  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
  };

  const logout = () => {
    window.location.href = "https://omayritys-functions.azurewebsites.net/api/logout";
  }

  return (
      <header>
        <div style={{
          display: 'flex',
          fontWeight: 600,
          justifyContent: "space-between",
        }}>
          <div style={{
            color: tokens.colors.brandBase,
            fontSize: '28px',
          }}>
            {t("CompanyRegister.header.title")}
          </div>

          <div style={{
            display: 'flex',
            textAlign: "right",
            verticalAlign: "middle",
            lineHeight: 1
          }}>
            
            <div style={{
              display: 'flex'
            }}>
              <ButtonLink
                  style={{textTransform: "uppercase", fontSize: '22px', margin: `0 ${tokens.spacing.xxs}`}}
                  onClick={() => changeLanguage('fi') }>
                FI
              </ButtonLink>
              <ButtonLink
                  style={{textTransform: "uppercase", fontSize: '22px', margin: `0 ${tokens.spacing.xxs}`}}
                  onClick={() => changeLanguage('en') }>
                EN
              </ButtonLink>

              { loggedIn && 
                <ButtonLink style={{ fontSize: '22px', margin: `0 ${tokens.spacing.xxs} 0 50px`}} onClick={() => logout() }>
                  {t("CompanyRegister.header.logout")}
                </ButtonLink>
              }
         
            </div>
          </div>
        </div>
      </header>
  );
};

export default Header;
