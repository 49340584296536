/** @jsxImportSource @emotion/react */
import {useContext, useState} from 'react';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { suomifiDesignTokens as tokens, Button, Heading, Text } from 'suomifi-ui-components'
import { FlexCol, FlexRow } from '../common/CommonStyles'
import { TextField } from '@mui/material';
import LoadingModal from '../modals/LoadingModal'
import * as OmaYritysApi from "../../api/OmaYritysApi";
import { ButtonLink } from '../common/InputStyles'
import { AppStateContext } from '../../state/AppStateContext';

const LoginView = () => {
  const appContext = useContext(AppStateContext);
  const [businessId, setBusinessId] = useState('7011367-6');
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string|undefined>(undefined);

  const doLogin = (businessId: string) => {
    setError(undefined);
    setLoading(true);
    OmaYritysApi.GetCompany(businessId)
    .then(res => {
      if (res.data) {
        appContext.setCompany(res.data);
      }
      setLoading(false);
      navigate("/");
    })
    .catch(err => {
      setError("CompanyRegister.login.companyNotFound");
      setLoading(false);
      console.log(err);
    })
  }

  const doSuomiFiLogin = async () => {
    
    const apiRoot = process.env.REACT_APP_OMAYRITYS_FUNCTIONS_URL || "http://localhost:7071/api"; //(window.location.origin + "/api"); 
    console.log(apiRoot);
    // switch(window.location.hostname) {
    //   case "http:"
    // }
    const relayState = window.location.host.replaceAll(".","").replaceAll(":","");
    window.location.href = apiRoot + `/authenticate?relayStateAction=${encodeURIComponent(relayState)}&language=${encodeURIComponent(i18n.language)}`; // oma-yritys-api authenticate...
    
    // const data = await fetch(`${apiRoot}/authenticate?relayStateAction=${relayState}&language=${i18n.language}`);
    // const json = await data.json();
    // console.log(JSON.stringify(json));
    // window.location.href = json.redirectUri;
    // fetch(`{apiRoot}/authenticateX?relayStateAction=${relayState}&language=${i18n.language}`).then(x => {
    //   debugger;
    //   x.json().then(data => window.location.href);
    //   window.location.href = x.data.redirectUri as string;
    // })
    // OmaYritysApi.Authenticate2(relayState, i18n.language).then(x => {
    //   debugger;
    //   window.location.href = x.data.redirectUri as string;
    // });
  }

  return (
    <div>
      <FlexRow>
        <FlexCol>
          <div>
            <Heading variant='h1hero'>
              {t("CompanyRegister.login.heading")}
            </Heading>
          </div>

          <Text variant="lead">
            {t("CompanyRegister.login.loginWithSuomiFi")}
          </Text>

          <div style={{ margin: `${tokens.spacing.m} 0` }}>
            <Button icon={"login"} onClick={() => doSuomiFiLogin()}>
              {t("CompanyRegister.login.suomiFiLoginBtn")}
            </Button>
          </div>

          <Text variant="lead">
            {t("CompanyRegister.login.useBusinessIdTestLogin")}
          </Text>

          { error && 
            <Text variant="lead" color="alertBase">
              {t(error)}
            </Text>
          }
          <div>
            <TextField type="text" label={t("CompanyRegister.login.companyLabel")} value={businessId} onChange={e => setBusinessId(e.target.value)} variant="outlined" />
          </div>
          <div style={{ margin: `${tokens.spacing.m} 0` }}>
            <Button icon={"login"} onClick={() => doLogin(businessId)}>
              {t("CompanyRegister.login.loginbutton")}
            </Button>
          </div>

          <div style={{color: tokens.colors.depthDark1}}>
            {t("CompanyRegister.login.useTestLogins")}
            <ul>
              <li>
                <ButtonLink onClick={() => doLogin("7017001-3")}>Raksa Oy</ButtonLink>
              </li>
              <li>
                <ButtonLink onClick={() => doLogin("7011367-6")}>Hosharaff 238 Testifirma</ButtonLink>
              </li>
              <li>
                <ButtonLink onClick={() => doLogin("7025394-3")}>Acanne Testifirma</ButtonLink>
              </li>
              <li>
                <ButtonLink onClick={() => doLogin("7023610-7")}>Vasagrande Testifirma</ButtonLink>
              </li>
            </ul>
          </div>
        </FlexCol>
      </FlexRow>
      <LoadingModal isOpen={loading} contentLabel={"Loading"} message={"Loading..."}/>
    </div>
  )
}

export default LoginView
