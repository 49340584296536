import React, { useState, useContext } from 'react';
import { suomifiDesignTokens as tokens } from 'suomifi-ui-components';
import { AppStateContext } from '../state/AppStateContext';
import { FlexRow, InfoNotification } from './common/CommonStyles';
import { ButtonLinkLead } from './common/InputStyles';
import Modal from './common/Modal';
import WalletConnection from './modals/WalletConnection';
import { useTranslation } from 'react-i18next';
import { Issuer } from '../models/CloudWalletTypes';


const WalletNotifications: React.FC = () => {
  const appContext = useContext(AppStateContext);
  const { t } = useTranslation(["translation"]);
  const [openInvitationModal, setOpenInvitationModal] = useState<boolean>(false);
  const [showInvitationNotification, setShowInvitationNotification] = useState<boolean>(true);

  return (
    <>
      {/* Show connection invitation notification if connection is not created */}
      {appContext.company && !appContext.isLoadingApp && !appContext.walletError && !appContext.connections[Issuer.COMPANYREGISTER] && showInvitationNotification && 
        <FlexRow style={{margin: `${tokens.spacing.m} 0`}}>
          <InfoNotification closeText='Close' onCloseButtonClick={() => setShowInvitationNotification(false)}>
            <ButtonLinkLead onClick={() => setOpenInvitationModal(true)}>{t("CompanyRegister.components.WalletNotifications.WalletInvitation.createInvitation")}</ButtonLinkLead>
          </InfoNotification>
          <Modal isOpen={openInvitationModal} closeModal={() => setOpenInvitationModal(false)} closeOnEscOrOverlayClick={true}>
            <WalletConnection companyID={appContext.company.id} closeModal={() => setOpenInvitationModal(false)} />
          </Modal>
        </FlexRow>
      }
    </>

  );
}

export default WalletNotifications;