import styled from '@emotion/styled'
import {Heading, suomifiDesignTokens as tokens} from "suomifi-ui-components";
import {MainWithBorder} from "./CommonStyles";

export const Toolbar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: ${tokens.spacing.l} 0 ${tokens.spacing.s};
`;

export const Main = styled(MainWithBorder)`
  padding: ${tokens.spacing.xl};
  line-height: 2;
  @media (max-width: 800px) {
    padding: ${tokens.spacing.l} ${tokens.spacing.m};
  }
`;

export const Section = styled.section`
  margin: ${tokens.spacing.l} 0;
`;

export const Heading1 = styled(Heading)`
  margin: ${tokens.spacing.m} 0;
`;

export const Heading2 = styled(Heading)`
  margin: ${tokens.spacing.m} 0;
`;
