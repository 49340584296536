/** @jsxImportSource @emotion/react */
import { jsx } from '@emotion/react'
import React, { useContext, useState } from "react";
import { AppStateContext } from "../state/AppStateContext";
import { useTranslation } from "react-i18next";
import { Heading, suomifiDesignTokens as tokens } from "suomifi-ui-components";
import { Box, Grid, IconButton } from "@mui/material";
import RefreshIcon from '@mui/icons-material/Refresh';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import * as AcaPyTypes from '../models/AcaPyModels';
import { ButtonLink } from "./common/InputStyles";
import Modal from "./common/Modal";
import WalletConnection from "./modals/WalletConnection";
import * as CompanyRegisterApi from '../api/CompanyRegisterApi';
import * as VeroApi from '../api/VeroApi';
import { FlexColTight, panelWithShadowCss } from "./common/CommonStyles";
import { CredentialExhangeReadyStates, Issuer } from '../models/CloudWalletTypes';

const CredentialsInfo: React.FC = () => {
  const appContext = useContext(AppStateContext);
  const { t, i18n } = useTranslation(["translation"]);
  const [openInvitationModal, setOpenInvitationModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<string|undefined>(undefined);

  const getConnectionStatus = (connection?: AcaPyTypes.ConnRecord|null) => {
    if (connection && connection.rfc23_state !== "invitation-sent") {
      if (connection.state === "active") {
        return t("CompanyRegister.components.CredentialsInfo.ConnectionStatus.Connected");
      }
      return t("CompanyRegister.components.CredentialsInfo.ConnectionStatus.Pending");
    }
    else {
      return t("CompanyRegister.components.CredentialsInfo.ConnectionStatus.NotConnected");
    }
  }

  const getCredentialStatus = (cred?: AcaPyTypes.V10CredentialExchange) => {
    if (cred) {
      if (CredentialExhangeReadyStates.find(it => it === cred.state) !== undefined) {
        return t("CompanyRegister.components.CredentialsInfo.CredentialStatus.Issued");
      }
      return t("CompanyRegister.components.CredentialsInfo.CredentialStatus.Pending");
    }
    return t("CompanyRegister.components.CredentialsInfo.CredentialStatus.NotIssued");
  }

  const requestCredential = (issuer: string, connectionId: string, cred_def_id: string) => {
    setLoading(cred_def_id);
    issueCredentialPromise(issuer, connectionId, cred_def_id)
    .then(res => {
      appContext.getCredentialExchangesAsync()
      .then(() => setLoading(undefined));
    });
  }

  const issueCredentialPromise = (issuer: string, connectionId: string, cred_def_id: string) => {
    if (issuer === Issuer.VERO) {
      return VeroApi.IssueCredential(connectionId, cred_def_id);
    }
    return CompanyRegisterApi.IssueCredential(connectionId, cred_def_id);
  }

  interface Props {
    issuer: string,
    cred_def_id: string,
    credEx?: AcaPyTypes.V10CredentialExchange
  }

  const CredentialStatus: React.FC<Props> = ({issuer, cred_def_id, credEx}) => {
    return (
      <>
        <span>{getCredentialStatus(credEx)}</span>
        { !credEx && !loading && <ButtonLink style={{marginLeft: tokens.spacing.xs}} onClick={() => requestCredential(issuer, appContext.connections[issuer]!.connection_id!, cred_def_id)}>{t("CompanyRegister.components.CredentialsInfo.requestCredential")}</ButtonLink>}
        { !credEx && loading === cred_def_id && <span style={{marginLeft: tokens.spacing.xs}}>{t("CompanyRegister.components.CredentialsInfo.requesting")}</span>}
      </>
    )
  }
  
  return appContext.company && !appContext.isLoadingApp ? (
    <FlexColTight css={panelWithShadowCss}>
      <Box sx={{ flexGrow: 1 }} style={{ display: 'flex', justifyContent: 'space-between'}}>
        <div>
          <Heading variant="h2" smallScreen>
              {t("CompanyRegister.components.CredentialsInfo.heading")}
              {/* <IconButton onClick={refreshConnections}>{loading ? <HourglassEmptyIcon/> : <RefreshIcon/>}</IconButton> */}
            </Heading>
        </div>
        <div>
          { appContext.connections[Issuer.COMPANYREGISTER]?.state === "active" ? 
            <div style={{color: tokens.colors.successDark1}}>
              <CheckCircleIcon style={{verticalAlign: 'text-bottom', marginRight: tokens.spacing.xxs}}/>
              <span>{getConnectionStatus(appContext.connections[Issuer.COMPANYREGISTER])}</span>
            </div> :
            <div style={{color: tokens.colors.alertBase}}>
              <RemoveCircleIcon style={{verticalAlign: 'text-bottom', marginRight: tokens.spacing.xxs}}/>
              <span>{getConnectionStatus(appContext.connections[Issuer.COMPANYREGISTER])}</span>
            </div>
          }
        </div>
      </Box>
      <Box sx={{ flexGrow: 1 }}>
        { appContext.isLoadingApp ? 
          <span>{t("common.loadingApp")}</span>
          :
          <>
            <Grid container>
              <Grid item xs={4} md={4}>
                <strong>{t("CompanyRegister.components.CredentialsInfo.credential")}</strong>
              </Grid>
              <Grid item xs={4} md={4}>
                <strong>{t("CompanyRegister.components.CredentialsInfo.issuer")}</strong>
              </Grid>
              <Grid item xs={4} md={4}>
                <strong>{t("CompanyRegister.components.CredentialsInfo.status")}</strong>
              </Grid>
            </Grid>
            { appContext.connections[Issuer.COMPANYREGISTER] && appContext.connections[Issuer.COMPANYREGISTER]?.state === "active" ? 
              <>
                { Object.entries(appContext.availableCredentials).map(([key, value], i) => {
                  return value.map((ac, index) => (
                    <Grid container key={`ac-${i}-${index}`}>
                      <Grid item xs={4} md={4}>
                        <span>{i18n.language === "fi" ? ac.title_fi : ac.title_en}</span>
                      </Grid>
                      <Grid item xs={4} md={4}>
                        <span>{t(`CompanyRegister.components.CredentialsInfo.Issuers.${key}`)}</span>
                      </Grid>
                      <Grid item xs={4} md={4}>
                        <CredentialStatus issuer={key} cred_def_id={ac.cred_def_id} credEx={appContext.credentialExchanges[key]?.find(it => it.credential_definition_id === ac.cred_def_id)} />
                      </Grid>
                    </Grid>
                  ))
                })}
              </>
              :
              <Grid container>
                <Grid item xs={12}>
                  <ButtonLink style={{marginLeft: tokens.spacing.xs}} onClick={() => setOpenInvitationModal(true)}>{t("CompanyRegister.components.CredentialsInfo.openInvitation")}</ButtonLink>
                </Grid>
              </Grid>
            }
          </>
          
        }
      </Box>
      { !appContext.connections[Issuer.COMPANYREGISTER] && 
        <Modal isOpen={openInvitationModal} closeModal={() => setOpenInvitationModal(false)} closeOnEscOrOverlayClick={true}>
          <WalletConnection companyID={appContext.company.id} closeModal={() => setOpenInvitationModal(false)} />
        </Modal>
      }
    </FlexColTight>
  ) :
  (
    <></>
  );
};

export default CredentialsInfo;
