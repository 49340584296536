import React, { Suspense } from 'react';
import { css, Global } from '@emotion/react';
import {BrowserRouter as Router, Navigate, Route, Routes} from "react-router-dom";
import { suomifiDesignTokens as tokens } from 'suomifi-ui-components';
import { ContentContainer, HeaderBackground, TopNavigationBackground } from './components/common/CommonStyles';
import Header from './components/common/Header';
import Navigation from './components/common/Navigation';
import Home from './components/Home';
import AppContextProvider, { AppStateContext } from './state/AppStateContext';
import LoginView from './components/Login/LoginView';
import WalletNotifications from './components/WalletNotifications';
import AuthenticatedView from './components/authentication/AuthenticatedView';

const App: React.FC = () => {

  return (
    <Suspense fallback="loading">
      <AppContextProvider>
        <Global styles={css`
          body {
            background-color: ${tokens.colors.depthLight3};
            font-family: ${tokens.values.typography.bodyText.fontFamily};
            font-size: ${tokens.values.typography.bodyText.fontSize.value}${tokens.values.typography.bodyText.fontSize.unit};
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            line-height: ${tokens.values.typography.bodyText.lineHeight.value}${tokens.values.typography.bodyText.lineHeight.unit};
            margin: 0;
          }
          a {
            color: ${tokens.colors.highlightBase};
            text-decoration: none; 
          }
          a:visited {
            color: ${tokens.colors.accentTertiaryDark1}
          }
          code {
            white-space: pre-wrap !important;
          }
          label {
            font-weight: ${tokens.values.typography.bodySemiBold.fontWeight}
          } 
          hr {
            border: 0;
            border-bottom: 1px solid ${tokens.colors.depthLight3};
            margin: ${tokens.spacing.m} 0;
          }
          ul, ol {
            margin: 0;
            padding: 0;
            @media (max-width: 1200px) {
              list-style-position: inside;              
            }
          }
          li {
            margin: 0;
            padding: 0;            
          }
          p {
            margin: ${tokens.spacing.m} 0;
          }
        `}
        />
        <Router>
          <HeaderBackground>
            <ContentContainer>
              <Header/>
            </ContentContainer>
          </HeaderBackground>

          <TopNavigationBackground>
            <ContentContainer>
              <Navigation/>
            </ContentContainer>
          </TopNavigationBackground>

          <ContentContainer>
            <WalletNotifications/>
          </ContentContainer>

          <ContentContainer>
            <AppStateContext.Consumer>
              {state => state.company ? // TODO refactor below:
                <Routes>
                  <Route path="/" element={<Home/>}/>
                  <Route path="/authenticated" element={<AuthenticatedView />}/>
                  
                  <Route path="/login" element={<Navigate to={"/"}/>}/>
                </Routes> :
                <Routes>
                  <Route path="/authenticated" element={<AuthenticatedView />}/>
                  <Route path="/login" element={<LoginView/>}/>
                  <Route path="/" element={<Navigate to={"/login"}/>}/>
                 
                </Routes>
                
              }
            </AppStateContext.Consumer>
            <div style={{ boxSizing: "border-box", position: "absolute", bottom: "0px", left: "0px", right: "0px", top: "auto", width: "100%", borderTop: "1px solid #ddd", backgroundColor: "#eee", display: "block", padding: "10px", marginTop: "20px", textAlign: "center" }}>
              <a href="https://minisuomi.fi">tietoja minisuomen sivustoista</a>
            </div>
          </ContentContainer>
        </Router>
      </AppContextProvider>
    </Suspense>
  )
};

export default App;