import React, { useContext } from "react";
import { AppStateContext } from "../state/AppStateContext";
import { useTranslation } from "react-i18next";
import Card from "./common/Card";
import { Box, Grid, Tooltip } from "@mui/material";

const CompanyBasicInfo: React.FC = () => {
  const appContext = useContext(AppStateContext);
  const { t } = useTranslation(["translation"]);

  const PrintActivities = (activities?: string[]) => {
    return activities?.join(", ");
  }
  
  return (
    <Card title={t("CompanyRegister.models.basicInfo.title")}>
      { appContext.company && appContext.basicInfo &&
        <Box sx={{ flexGrow: 1 }}>
          <Grid container>
            <Grid item xs={6} md={6}>
              <Tooltip placement="top" title={t("CompanyRegister.models.basicInfo.name.description")}>
                <span>{t("CompanyRegister.models.basicInfo.name.title")}</span>
              </Tooltip>
            </Grid>
            <Grid item xs={6} md={6}>
              <span>{appContext.basicInfo.name}</span>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={6} md={6}>
              <Tooltip placement="top" title={t("CompanyRegister.models.basicInfo.registrationDate.description")}>
                <span>{t("CompanyRegister.models.basicInfo.registrationDate.title")}</span>
              </Tooltip>
            </Grid>
            <Grid item xs={6} md={6}>
              <span>{appContext.basicInfo.registrationDate}</span>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={6} md={6}>
              <Tooltip placement="top" title={t("CompanyRegister.models.basicInfo.legalForm.description")}>
                <span>{t("CompanyRegister.models.basicInfo.legalForm.title")}</span>
              </Tooltip>
            </Grid>
            <Grid item xs={6} md={6}>
              <span>{appContext.basicInfo.legalForm}</span>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={6} md={6}>
              <Tooltip placement="top" title={t("CompanyRegister.models.basicInfo.legalStatus.description")}>
                <span>{t("CompanyRegister.models.basicInfo.legalStatus.title")}</span>
              </Tooltip>
            </Grid>
            <Grid item xs={6} md={6}>
              <span>{appContext.basicInfo.legalStatus}</span>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={6} md={6}>
              <Tooltip placement="top" title={t("CompanyRegister.models.basicInfo.activity.description")}>
                <span>{t("CompanyRegister.models.basicInfo.activity.title")}</span>
              </Tooltip>
            </Grid>
            <Grid item xs={6} md={6}>
              <span>{PrintActivities(appContext.basicInfo.activity)}</span>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} md={12}>
              <Tooltip placement="top" title={t("CompanyRegister.models.basicInfo.registeredAddress.description")}>
                <span>{t("CompanyRegister.models.basicInfo.registeredAddress.title")}</span>
              </Tooltip>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={6} md={6}>
              <Tooltip placement="top" title={t("CompanyRegister.models.basicInfo.registeredAddress.fullAddress.description")}>
                <span css={{paddingLeft: '10px'}}>{t("CompanyRegister.models.basicInfo.registeredAddress.fullAddress.title")}</span>
              </Tooltip>
            </Grid>
            <Grid item xs={6} md={6}>
              <span>{appContext.basicInfo.registeredAddress.fullAddress}</span>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={6} md={6}>
              <Tooltip placement="top" title={t("CompanyRegister.models.basicInfo.registeredAddress.thoroughfare.description")}>
                <span css={{paddingLeft: '10px'}}>{t("CompanyRegister.models.basicInfo.registeredAddress.thoroughfare.title")}</span>
              </Tooltip>
            </Grid>
            <Grid item xs={6} md={6}>
              <span>{appContext.basicInfo.registeredAddress.thoroughfare}</span>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={6} md={6}>
              <Tooltip placement="top" title={t("CompanyRegister.models.basicInfo.registeredAddress.locatorDesignator.description")}>
                <span css={{paddingLeft: '10px'}}>{t("CompanyRegister.models.basicInfo.registeredAddress.locatorDesignator.title")}</span>
              </Tooltip>
            </Grid>
            <Grid item xs={6} md={6}>
              <span>{appContext.basicInfo.registeredAddress.locatorDesignator}</span>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={6} md={6}>
              <Tooltip placement="top" title={t("CompanyRegister.models.basicInfo.registeredAddress.locatorName.description")}>
                <span css={{paddingLeft: '10px'}}>{t("CompanyRegister.models.basicInfo.registeredAddress.locatorName.title")}</span>
              </Tooltip>
            </Grid>
            <Grid item xs={6} md={6}>
              <span>{appContext.basicInfo.registeredAddress.locatorName}</span>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={6} md={6}>
              <Tooltip placement="top" title={t("CompanyRegister.models.basicInfo.registeredAddress.addressArea.description")}>
                <span css={{paddingLeft: '10px'}}>{t("CompanyRegister.models.basicInfo.registeredAddress.addressArea.title")}</span>
              </Tooltip>
            </Grid>
            <Grid item xs={6} md={6}>
              <span>{appContext.basicInfo.registeredAddress.addressArea}</span>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={6} md={6}>
              <Tooltip placement="top" title={t("CompanyRegister.models.basicInfo.registeredAddress.postCode.description")}>
                <span css={{paddingLeft: '10px'}}>{t("CompanyRegister.models.basicInfo.registeredAddress.postCode.title")}</span>
              </Tooltip>
            </Grid>
            <Grid item xs={6} md={6}>
              <span>{appContext.basicInfo.registeredAddress.postCode}</span>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={6} md={6}>
              <Tooltip placement="top" title={t("CompanyRegister.models.basicInfo.registeredAddress.postName.description")}>
                <span css={{paddingLeft: '10px'}}>{t("CompanyRegister.models.basicInfo.registeredAddress.postName.title")}</span>
              </Tooltip>
            </Grid>
            <Grid item xs={6} md={6}>
              <span>{appContext.basicInfo.registeredAddress.postName}</span>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={6} md={6}>
              <Tooltip placement="top" title={t("CompanyRegister.models.basicInfo.registeredAddress.poBox.description")}>
                <span css={{paddingLeft: '10px'}}>{t("CompanyRegister.models.basicInfo.registeredAddress.poBox.title")}</span>
              </Tooltip>
            </Grid>
            <Grid item xs={6} md={6}>
              <span>{appContext.basicInfo.registeredAddress.poBox}</span>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={6} md={6}>
              <Tooltip placement="top" title={t("CompanyRegister.models.basicInfo.registeredAddress.adminUnitLevel_1.description")}>
                <span css={{paddingLeft: '10px'}}>{t("CompanyRegister.models.basicInfo.registeredAddress.adminUnitLevel_1.title")}</span>
              </Tooltip>
            </Grid>
            <Grid item xs={6} md={6}>
              <span>{appContext.basicInfo.registeredAddress.adminUnitLevel_1}</span>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={6} md={6}>
              <Tooltip placement="top" title={t("CompanyRegister.models.basicInfo.registeredAddress.adminUnitLevel_2.description")}>
                <span css={{paddingLeft: '10px'}}>{t("CompanyRegister.models.basicInfo.registeredAddress.adminUnitLevel_2.title")}</span>
              </Tooltip>
            </Grid>
            <Grid item xs={6} md={6}>
              <span>{appContext.basicInfo.registeredAddress.adminUnitLevel_2}</span>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={6} md={6}>
              <Tooltip placement="top" title={t("CompanyRegister.models.basicInfo.registeredAddress.addressId.description")}>
                <span css={{paddingLeft: '10px'}}>{t("CompanyRegister.models.basicInfo.registeredAddress.addressId.title")}</span>
              </Tooltip>
            </Grid>
            <Grid item xs={6} md={6}>
              <span>{appContext.basicInfo.registeredAddress.addressId}</span>
            </Grid>
          </Grid>
        </Box>
      }
    </Card>
  );
};

export default CompanyBasicInfo;
