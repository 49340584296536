import React, { useContext, useState } from "react";
import { Paragraph, suomifiDesignTokens as tokens } from "suomifi-ui-components";
import { FlexCol, FlexRow } from "../common/CommonStyles";
import { Heading2 } from "../common/DocumentStyles";
import QRCode from 'qrcode.react';
import { useTranslation } from "react-i18next";
import { Button, TextField } from "@mui/material";
import * as AcaPyTypes from '../../models/AcaPyModels';
import { AppStateContext } from "../../state/AppStateContext";
import * as CompanyRegisterApi from '../../api/CompanyRegisterApi';

interface Props {
  companyID: string,
  closeModal: () => void
}

const WalletConnection: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation(["translation"]);

  const appContext = useContext(AppStateContext);

  const [connectionUrl, setConnectionUrl] = useState('');

  const [urlError, setUrlError] = useState({
    error: false,
    message: ''
  });

  const handleAcceptConnection = async () => {
    try {
      const response = await CompanyRegisterApi.AcceptConnection(props.companyID, connectionUrl);
      if(response.status === 200) {
        setConnectionUrl('');
        setUrlError({
          error: false,
          message: ''
        });
        appContext.refreshWalletState();
        props.closeModal();
      }
    } catch(err) {
      setUrlError({
        error: true,
        message: 'Invalid URL'
      });
    }
  }

  return (
    <FlexCol>
      <FlexRow>
        <Heading2 variant="h2"><b>{t("CompanyRegister.components.WalletConnection.heading")}</b></Heading2>
      </FlexRow>
      { urlError.error &&
        <FlexRow>
          <Paragraph style={{margin: `${tokens.spacing.m} 0`}}>
            {t("CompanyRegister.components.WalletConnection.connectionError")}
          </Paragraph>
        </FlexRow>
      }
      <FlexRow>
        <TextField error={urlError.error} helperText={urlError.message} onChange={e => setConnectionUrl(e.target.value)} value={connectionUrl} required fullWidth variant='outlined' label='Invite URL' />
      </FlexRow>
      <FlexRow>
        <Button onClick={handleAcceptConnection} variant='contained' color='primary'>{t("CompanyRegister.components.WalletConnection.submitButton")}</Button>
      </FlexRow>
    </FlexCol>
  );
}

export default WalletConnection;