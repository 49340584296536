import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom';

import * as OmaYritysApi from "../../api/OmaYritysApi";
import { AppStateContext } from '../../state/AppStateContext';
import { ButtonLink } from '../common/InputStyles';

interface ICompanyData
{
  name: string;
  identifier: string;
  roles: string[];

}

// TODO DO not use in production, this is quick and dirty solution...
const AuthenticatedView = () => {
  const appContext = useContext(AppStateContext);
  //const [companies, setCompanies] = useState<Company[]>([]);
  const [loading, setLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (!loading && searchParams.get("data") !== null) {
      // DO NOT USE IN PRODUCTION (need to be sure that data actually comes from valtuudet, now it can be injected to the query)
      debugger;
      const decodedJsonString = atob(searchParams.get("data") as string);
      //const decodedJsonString = Buffer.from(searchParams.get("data") as string, "base64").toLocaleString();
      const data = JSON.parse(decodedJsonString) as ICompanyData[];
      if (data.length > 0){
        setLoading(true);
        const businessId = data[0].identifier;
        OmaYritysApi.GetCompany(businessId)
        .then(res => {
          if (res.data) {
            appContext.setCompany(res.data);
          }
          setLoading(false);
          navigate("/");
        }, (err) => {
          alert(`failed to find company for ${businessId} from database`)
        });
      }
    }
  }, []);

  
  

  const logout = ()=> {
    const apiRoot = process.env.REACT_APP_OMAYRITYS_FUNCTIONS_URL || "http://localhost:7071/api";
    window.location.href = apiRoot + "/logout";
  }

  return (
    <div>
      { loading ?
        <p>Lataa valtuutetun yrityksen tietoja...</p>
        :
        <p>Ei valtuuksia yritykseen. <br/>
           <ButtonLink onClick={logout}>Kirjaudu ulos</ButtonLink>
        </p>
      }
      { loading && <p>Loading...</p>}
    </div>
  )
}

export default AuthenticatedView