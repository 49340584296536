import React, { useEffect, useState, PropsWithChildren } from "react";
import log from "loglevel";
import _ from "lodash";
import { Company } from "../models/OmaXModels";
import { BasicInfo } from "../models/CompanyTypes";
import { FetchNSGAgentBasicInformation } from "../api/VirtualFinlandTestbedApi";
import * as CompanyRegisterApi from '../api/CompanyRegisterApi';
import * as VeroApi from '../api/VeroApi';
import * as AcaPyTypes from '../models/AcaPyModels';
import useInterval from "../utils/useInterval";
import { AvailableCredential, CredentialExhangeReadyStates, Issuer } from "../models/CloudWalletTypes";
import { AxiosResponse } from "axios";

/* Connection/invitation value:
 * undefined => Not fetched from api or an error happened when requesting from api
 * null => Api returned null value (item does not exist in the api / wallet)
 */

interface AppState {
  isLoadingApp: boolean,
  company?: Company,
  basicInfo?: BasicInfo,
  // invitation?: AcaPyTypes.InvitationResult|null,
  connections: {[issuer: string]: AcaPyTypes.ConnRecord|null},
  credentialExchanges: {[issuer: string]: AcaPyTypes.V10CredentialExchange[]},
  availableCredentials: {[issuer: string]: AvailableCredential[]},
  walletError?: string
}

export type AppStateType = AppState & {
  // Functions
  loadAppStateAsync: () => Promise<void>,
  setCompany: (company: Company) => void,
  // setInvitation: (invitation: AcaPyTypes.InvitationResult) => void,
  getConnectionsAsync: () => Promise<void>,
  getCredentialExchangesAsync: () => Promise<void>,
  refreshWalletState: () => Promise<void>,
}

export const DefaultAppState: AppState = {
  isLoadingApp: true,
  company: undefined,
  basicInfo: undefined,
  // invitation: undefined,
  connections: {},
  credentialExchanges: {},
  availableCredentials: {},
  walletError: undefined
}

export const DefaultAppStateContext: AppStateType = {
  ...DefaultAppState,
  loadAppStateAsync: () => new Promise<void>(resolve => resolve()),
  setCompany: (company: Company) => { return; },
  // setInvitation: (invitation: AcaPyTypes.InvitationResult) => { return; },
  getConnectionsAsync: () => new Promise<void>(resolve => resolve()),
  getCredentialExchangesAsync: () => new Promise<void>(resolve => resolve()),
  refreshWalletState: () => new Promise<void>(resolve => resolve()),
}

// AppContext with default values. AppContextProvider replaces defaults with the real values.
export const AppStateContext = React.createContext<AppStateType>(DefaultAppStateContext);

// An alternative way to initialize a default AppStateContext would be the following.
// export const AppStateContext = React.createContext<AppStateType>({} as AppStateType);
// Here we skip creating dummy context by telling to Typescript compiler that an empty object is a valid AppStateType.
// If you do always make sure to only access the context inside of TodoContextProvider with useContext 
// then you can safely skip initialising AppStateType inside of createContext because that initial value 
// will never actually be accessed.

enum PromiseTypes {
  COMPANY_BASIC_INFO = "companyBasicInfo",
  CONNECTIONS = "connections",
  // INVITATION = "invitation",
  CREDENTIAL_EXCHANGES = "credentialExchanges",
  AVAILABLE_CREDENTIALS = "availableCredentials"
}

/**
 * AppContextProvider contains "public" getter methods and "private" fetch methods e.g. getCompanyAsync and fetchCompany
 * When fetching data from APIs, ongoing request promises are stored in the *promises* state. Getter methods (e.g. getCompanyAsync) 
 * return the existing request promise if one exists. If it does not exist, it calls fetch method (e.g. fetchCompany) 
 * to create a new request promise. The idea is to prevent concurrent requests to fetch the same data.
 * Methods that have a comment "public" are accessible outside of the AppContextProvider.
 * Methods that have a comment "private" are for internal use of the AppContextProvider only.
 */
const AppContextProvider: React.FC<PropsWithChildren> = ({children}) => {
  const [appState, setAppState] = useState<AppState>(DefaultAppState);
  const [refreshWalletInterval, setRefreshWalletInterval] = useState<boolean>(false);
  const [refreshCredExsInterval, setRefreshCredExsInterval] = useState<boolean>(false);
  const [pendingExchanges, setPendingExchanges] = useState<AcaPyTypes.V10CredentialExchange[]>([]);
  // Contains ongoing request promises. To update state use methods addPromise and removePromise
  const [promises, setPromises] = useState<{[type: string]: Promise<any>}>({});
  const logger = log.getLogger(AppContextProvider.name);

  useEffect(() => {
    loadAppStateAsync();
  }, [appState.company]);

  useEffect(() => {
    getCredentialExchangesAsync();
  }, [appState.connections]);

  useEffect(() => {
    if (pendingExchanges.length > 0) {
      setRefreshCredExsInterval(true);
    }
    else {
      setRefreshCredExsInterval(false);
    }
  }, [pendingExchanges]);

  // private
  const addPromise = (type: string, promise: Promise<any>) => {
    setPromises(oldState => ({...oldState, [type]: promise}));
  }

  // private
  const removePromise = (type: string) => {
    setPromises(oldState => _.omit(oldState, type));
  }

  const addPendingExchanges = (connectionId: string, exchanges: AcaPyTypes.V10CredentialExchange[]) => {
    setPendingExchanges(oldState => {
      // At first, delete existing pending exhanges with connectionId
      let tempExchanges = oldState.filter(ex => ex.connection_id !== connectionId);
      // Then add new pending exhanges with connectionId
      tempExchanges.push(...exchanges);
      return tempExchanges;
    });
  }

  const deletePendingExchanges = (connectionId: string) => {
    setPendingExchanges(oldState => {
      let tempExchanges = oldState.filter(ex => ex.connection_id !== connectionId);
      return tempExchanges;
    });
  }
  
  // public
  const loadAppStateAsync = async (): Promise<void> => {
    logger.debug("ACP loadAppStateAsync");
    // Reset necessary stuff in app state
    setAppState(oldState => ({...oldState, isLoadingApp: true}));
    // Fetch/load necessary stuff asynchronously
    const basicInfoPromise = getCompanyBasicInfoAsync();
    const availableCredentialsPromise = getAvailableCredentialsAsync();
    const loadWalletStatePromise = loadWalletStateAsync();

    return Promise.all([basicInfoPromise, availableCredentialsPromise, loadWalletStatePromise])
    .then(() => {
      logger.debug("ACP loadAppStateAsync finished");
      setAppState(oldState => ({...oldState, isLoadingApp: false}));
      return;
    });
  }

  const loadWalletStateAsync = async (): Promise<void> => {
    logger.debug("ACP loadWalletStateAsync");
    await getConnectionsAsync();
  }

  // public
  const setCompany = (company: Company) => {
    setAppState(oldState => ({...oldState, company}));
  }

  const getCompanyBasicInfoAsync = async (): Promise<BasicInfo|undefined> => {
    if (appState.company?.code) {
      logger.debug("ACP getCompanyBasicInfoAsync");
      return promises[PromiseTypes.COMPANY_BASIC_INFO] ?? fetchCompanyBasicInfo(appState.company.code);
    }
    return new Promise(resolve => resolve(undefined));
  }

  const fetchCompanyBasicInfo = async (companyID: string): Promise<BasicInfo|undefined> => {
    const promise = FetchNSGAgentBasicInformation(companyID)
    .then(res => {
      if (res.data && res.data.length > 0) {
        const basicInfo = res.data[0];
        setAppState(oldState => ({...oldState, basicInfo}));
        return basicInfo;
      }
      return undefined;
    })
    .catch(err => {
      logger.error("ACP fetchCompanyBasicInfo error", err);
      return undefined;
    })
    .finally(() => removePromise(PromiseTypes.COMPANY_BASIC_INFO));
    addPromise(PromiseTypes.COMPANY_BASIC_INFO, promise);
    return promise;
  }

  const getConnectionsAsync = async (): Promise<void> => {
    if (appState.company?.id) {
      logger.debug("ACP getConnectionAsync");
      if (promises[PromiseTypes.CONNECTIONS] !== undefined) {
        return promises[PromiseTypes.CONNECTIONS];
      }
      const companyRegisterPromise = fetchConnection(Issuer.COMPANYREGISTER, appState.company.id);
      const veroPromise = fetchConnection(Issuer.VERO, appState.company.id);
      const connPromise = Promise.all([companyRegisterPromise, veroPromise])
      .then(conns => {
        if (conns.findIndex(it => it?.rfc23_state === "request-sent") > -1) {
          setRefreshWalletInterval(true);
        }
        else {
          setRefreshWalletInterval(false);
        }
      })
      .finally(() => removePromise(PromiseTypes.CONNECTIONS));
      addPromise(PromiseTypes.CONNECTIONS, connPromise);
      await connPromise;
    }
  }

  const fetchConnection = async (issuer: string, companyID: string): Promise<AcaPyTypes.ConnRecord|undefined|null> => {
    const promise = getConnectionPromise(issuer, companyID)
    .then(res => {
      if (res.data) {
        const connection = res.data;
        logger.debug("ACP fetchConnection ", connection);
        setAppState(oldState => ({...oldState, connections: {...oldState.connections, [issuer]: connection}}));
        return connection;
      }
      setAppState(oldState => ({...oldState, connections: {...oldState.connections, [issuer]: null}}));
      return null;
    })
    .catch(err => {
      logger.error("ACP fetchConnection error", err);
      setAppState(oldState => ({...oldState, walletError: "ConnectionError"}));
      return undefined;
    });
    return promise;
  }

  const getConnectionPromise = (issuer: string, companyID: string): Promise<AxiosResponse<AcaPyTypes.ConnRecord | null, any>> => {
    if (issuer === Issuer.VERO) {
      return VeroApi.GetConnection(companyID);
    }
    return CompanyRegisterApi.GetConnection(companyID);
  }

  const getCredentialExchangesAsync = async (): Promise<void> => {
    logger.debug("ACP getCredentialExchangesAsync");
    const proms = Object.entries(appState.connections).map(([key, value]) => {
      if (value?.connection_id) {
        return promises[`${PromiseTypes.CREDENTIAL_EXCHANGES}-${key}`] ?? fetchCredentialExchanges(key, value.connection_id);
      }
      return new Promise<AcaPyTypes.V10CredentialExchange[]>(resolve => resolve([]));
    });
    await Promise.all(proms);
  }

  // private
  const fetchCredentialExchanges = async (issuer: string, connectionId: string): Promise<AcaPyTypes.V10CredentialExchange[]> => {
    const promise = getCredentialExchangePromise(issuer, connectionId)
    .then(res => {
      if (res.data?.results) {
        const credentialExchanges = res.data?.results ?? [];
        const pendingExs = credentialExchanges.filter(ex => {
          // credEx is pending if it the state is not any of readyStates
          return CredentialExhangeReadyStates.find(it => it === ex.state) === undefined;
        });
        if (pendingExs.length > 0) {
          // There are pending credential exhanges
          addPendingExchanges(connectionId, pendingExs);
        }
        else {
          deletePendingExchanges(connectionId);
        }
        setAppState(oldState => ({...oldState, credentialExchanges: {...oldState.credentialExchanges, [issuer]: credentialExchanges}}));
        return res.data?.results ?? [];
      }
      return [];
    })
    .catch(err => {
      return [];
    })
    .finally(() => removePromise(`${PromiseTypes.CREDENTIAL_EXCHANGES}-${issuer}`));
    addPromise(`${PromiseTypes.CREDENTIAL_EXCHANGES}-${issuer}`, promise);
    return promise;
  }

  const getCredentialExchangePromise = (issuer: string, connectionId: string): Promise<AxiosResponse<AcaPyTypes.V10CredentialExchangeListResult | null, any>> => {
    if (issuer === Issuer.VERO) {
      return VeroApi.ListIssuedCredentials(connectionId);
    }
    return CompanyRegisterApi.ListIssuedCredentials(connectionId);
  }

  const getAvailableCredentialsAsync = async (): Promise<void> => {
    logger.debug("ACP getAvailableCredentialsAsync");
    if (promises[PromiseTypes.AVAILABLE_CREDENTIALS] !== undefined) {
      return promises[PromiseTypes.AVAILABLE_CREDENTIALS];
    }
    const companyRegisterPromise = fetchAvailableCredentials(Issuer.COMPANYREGISTER);
    const veroPromise = fetchAvailableCredentials(Issuer.VERO);
    const proms = Promise.all([companyRegisterPromise, veroPromise])
    .finally(() => removePromise(PromiseTypes.AVAILABLE_CREDENTIALS));
    addPromise(PromiseTypes.AVAILABLE_CREDENTIALS, proms);
    await proms;
  }

  const fetchAvailableCredentials = async (issuer: string): Promise<AvailableCredential[]> => {
    const promise = getAvailableCredentialsPromise(issuer)
    .then(res => {
      if (res.data) {
        const availableCredentials = res.data;
        setAppState(oldState => ({...oldState, availableCredentials: {...oldState.availableCredentials, [issuer]: availableCredentials}}));
        return availableCredentials;
      }
      setAppState(oldState => ({...oldState, availableCredentials: {...oldState.availableCredentials, [issuer]: []}}));
      return [] as AvailableCredential[];
    })
    .catch(err => {
      logger.error("ACP fetchAvailableCredentials error", err);
      setAppState(oldState => ({...oldState, walletError: "AvailableCredentialsError"}));
      return [] as AvailableCredential[];
    });
    return promise;
  }

  const getAvailableCredentialsPromise = (issuer: string) => {
    if (issuer === Issuer.VERO) {
      return VeroApi.GetAvailableCredentials();
    }
    return CompanyRegisterApi.GetAvailableCredentials();
  }

  const refreshWalletState = async (): Promise<void> => {
    await loadWalletStateAsync();
  }

  useInterval(async () => {
    if (refreshWalletInterval) {
      refreshWalletState();
    }
    if (refreshCredExsInterval) {
      getCredentialExchangesAsync();
    }
  }, refreshWalletInterval || refreshCredExsInterval ? 5000 : null)

  return (
    <AppStateContext.Provider value={{
      ...appState,
      loadAppStateAsync,
      setCompany,
      getConnectionsAsync,
      getCredentialExchangesAsync,
      refreshWalletState
    }}>
      {children}
    </AppStateContext.Provider>
  );
}

export default AppContextProvider;
