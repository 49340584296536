import React, { PropsWithChildren } from "react";
import {default as ReactModal} from 'react-modal';
import { Button } from "@mui/material";
import { FlexCol, FlexColTight } from "./CommonStyles";

interface Props {
  isOpen: boolean,
  closeModal: () => void,
  styleContent?: {},
  zIndex?: number|string,
  closeOnEscOrOverlayClick?: boolean
}

const Modal: React.FC<PropsWithChildren<Props>> = ({isOpen, closeModal, styleContent, zIndex, children, closeOnEscOrOverlayClick}) => {

  const defaultStyleContent = {
    top: '10%',
    margin: '0 auto',
    width: '50%',
    height: 'fit-content',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'white'
  };
  const defaultOverLay = {
    position: 'fixed' as 'fixed',
    display: 'flex',
    justifyContent: 'center',
    zIndex: zIndex ?? 10,
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    overflowY: "auto" as 'auto'
  }

  return closeOnEscOrOverlayClick ? (
    <ReactModal isOpen={isOpen} onRequestClose={closeModal} shouldCloseOnEsc shouldCloseOnOverlayClick style={{
      content: {
        ...defaultStyleContent, ...styleContent
      },
      overlay: {
        ...defaultOverLay
      }
    }} contentLabel="Ladataan...">
      <FlexCol>
      { children }
      <Button onClick={closeModal} style={{ fontSize: 14, justifyContent: 'flex-end', padding: '10px' }}>Close</Button>
      </FlexCol>
    </ReactModal>
  ) : (
    <ReactModal isOpen={isOpen} style={{
      content: {
        ...defaultStyleContent, ...styleContent
      },
      overlay: {
        ...defaultOverLay
      }
    }} contentLabel="Ladataan...">
      <FlexCol>
      { children }
      <Button onClick={closeModal} style={{ fontSize: 14, justifyContent: 'flex-end', padding: '10px' }}>Close</Button>
      </FlexCol>
    </ReactModal>
  );
}

export default Modal;