import axios, { AxiosResponse } from 'axios';
import * as AcaPyTypes from '../models/AcaPyModels';
import { AvailableCredential } from '../models/CloudWalletTypes';


const apiRootUrl = process.env.REACT_APP_VERO_API_URL || 'http://localhost:7072/api/Vero'
const apiKey = process.env.REACT_APP_VERO_API_KEY || "";

const api = axios.create({
  baseURL: apiRootUrl,
  headers: {
    "x-functions-key": apiKey
  },
});

export async function GetConnection(companyID: string): Promise<AxiosResponse<AcaPyTypes.ConnRecord | null>> {
  return api.get<AcaPyTypes.ConnRecord>(`/GetConnection`, {params: {companyID}});
}

export async function CreateInvitation(companyID: string, alias?: string): Promise<AxiosResponse<AcaPyTypes.InvitationResult | null>> {
  return api.post<AcaPyTypes.InvitationResult>(`/CreateInvitation`, null, {params: {companyID, alias}});
}

export async function AcceptConnection(companyID: string, inviteUrl: string): Promise<AxiosResponse<AcaPyTypes.ConnRecord | null>> {
  return api.post<AcaPyTypes.ConnRecord>(`/ReceiveConnection`, inviteUrl, {params: {companyID}});
}

export async function ListIssuedCredentials(connId: string): Promise<AxiosResponse<AcaPyTypes.V10CredentialExchangeListResult | null>> {
  return api.get<AcaPyTypes.V10CredentialExchangeListResult>(`/GetIssuedCredentials`, {params: {connId}});
}

export async function IssueCredential(connId: string, credDefId: string): Promise<AxiosResponse<AcaPyTypes.V10CredentialExchange | null>> {
  return api.post<AcaPyTypes.V10CredentialExchange>(`/IssueCredential`, null, {params: {connId, credDefId}});
}

export async function ListVerifications(companyID: string): Promise<AxiosResponse<AcaPyTypes.V10PresentationExchangeList | null>> {
  return api.get<any>(`/ListVerifications`, {params: {companyID}});
}

export async function GetAvailableCredentials(): Promise<AxiosResponse<AvailableCredential[]>> {
  return api.get<AvailableCredential[]>(`/GetAvailableCredentials`);
}

// export async function SubmitVerification(walletId: string, verificationId: string): Promise<AxiosResponse<void>> {
//   return api.post(`/SubmitVerification`, null, {params: {walletId, verificationId}});
// }

// export async function SubmitVerificationFromData(walletId: string, verificationData: string): Promise<AxiosResponse<void>> {
//   return api.post(`/SubmitVerificationFromData`, {walletId, verificationData});
// }

// export async function GetBasicMessages(companyID: string): Promise<AxiosResponse<BasicMessageWrapper[] | null>> {
//   return api.get<BasicMessageWrapper[]>(`/GetBasicMessages`, {params: {companyID}});
// }

// export async function SendBasicMessage(companyID: string, message: BasicMessage): Promise<AxiosResponse<void>> {
//   return api.post(`/SendBasicMessage`, message, {params: { companyID }});
// }
